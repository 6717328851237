<template>
  <div class="">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="pa-5">
        <h1 class="f-alfa primary--text">Super Admin / Reports</h1>
      </v-flex>
      <v-flex xs12 md6 class="mb-5 pa-5">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">Date</th>
                <th class="text-left uppercase primary--text">Bookings</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in sortedByDate" :key="item._id">
                <td>{{ index + 1 }}</td>
                <td>{{ item._id }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 md6 class="pa-5">
        <h2>Confirmed Bookings - {{ confirmed }}</h2>
        <h2>Cancelled Bookings - {{ cancelled }}</h2>
        <h2>Abandoned Bookings - {{ abandoned }}</h2>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { BASE_URL } from "../../config";
import Axios from "axios";
export default {
  data() {
    return {
      confirmed: 0,
      cancelled: 0,
      abandoned: 0,
      sortedByDate: [],
    };
  },
  mounted() {
    this.fetchReports();
  },
  methods: {
    async fetchReports() {
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url = BASE_URL + "/booking/reports/all";
      let { data } = await Axios.get(url, { headers });
      this.confirmed = data.confirmedBookingsCount;
      this.cancelled = data.cancelledBookingsCount;
      this.abandoned = data.pendingPaymentBookingsCount;
      this.sortedByDate = data.bookingsByDate;
    },
  },
};
</script>